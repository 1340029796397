/**
 * Created by gschulz on 17.04.14.
 */
let Abstract = (function () {

    let _private = {};

    _private.instance = this;

    /**
     *
     * @param {*} form
     * @param {function} successHandler
     * @param {object} [data]
     * @param {boolean} [saveWithComment]
     */
    this.saveForm = (form, successHandler, data = undefined, saveWithComment = false) => {
        let observer = new FormObserver(form);
        let errors   = observer.preCheckForms();
        observer.disableSaveButton();
        if (errors.length) {
            console.warn('Abstract.saveForm :: skip', errors);
            return;
        }
        let beforeSave = saveWithComment ? this.promptChangeLogComment(form) : Promise.resolve(null);
        beforeSave.then(comment => {
            if (_.isUndefined(data)) {
                data = form.serializeObject();
            }
            if (comment) {
                data.change_log_comment = comment;
            }
            setTimeout(() => {new FormObserver(form).disableSaveButton()}, 10);
            $.ajax({
                url:         form.attr('action'),
                type:        'post',
                data:        data,
                dataType:    'json',
                ajaxElement: $('body'),
                error(xhr, errorType, errorMessage) {
                    let error = {
                        type:         errorType,
                        message:      errorMessage,
                        responseText: xhr.responseText
                    };
                    CacheStorage.setCacheByKey('lastSaveError', error);
                    console.error(error);
                },
                success(response) {
                    let formParent = form.parent();
                    if (!_.isUndefined(response.data)) {
                        let responseElement = $(response.data);
                        if (!responseElement.is('form')) {
                            responseElement = responseElement.find('form');
                        }
                        form.replaceWith(responseElement);
                        form = responseElement;
                    }
                    form.find('div.tabs').find('li.ui-state-active')
                        .removeClass('ui-state-active')
                        .removeClass('ui-tabs-active');
                    let formStatus = new FormStatus().init();
                    let status     = formStatus.addStatus(response, '', '', formParent);
                    if (status === 'success' && !_.isUndefined(response.redirect) && response.redirect !== '') {
                        setTimeout(() => {location.href = response.redirect}, 1000);
                        return;
                    }
                    if (status !== 'success') {
                        response.error_message = response.error_message || [];
                        let error              = {
                            type:     status,
                            message:  response.error_message,
                            errors:   response.errors,
                            response: response
                        };
                        CacheStorage.setCacheByKey('lastSaveError', error);
                        console.warn(error);
                    }
                    successHandler.apply({}, [form, response]);
                }
            });
        }).catch(console.log);

    };

    this.promptChangeLogComment = form => {
        return new Promise(resolve => {
            new EfbHelperDialog({
                isConfirmDialog: true,
                dialogClass:     'light',
                width:           440,
                height:          200,
                html:            this.getChangeLogCommentElement(),
                buttons:         [
                    {
                        text:  EfbHelper._('button.cancel'),
                        class: 'button text light cancel',
                        click() {
                            if (form.data('FormObserver')) {
                                form.data('FormObserver').enableSaveButton();
                            }
                            $(this).dialog("close");
                        }
                    },
                    {
                        text:  EfbHelper._('button.save'),
                        class: 'button text save',
                        click() {
                            $(this).dialog("close");
                            let comment = $.trim($(this).find('#change-log-comment').val());
                            return resolve(comment || null)
                        }
                    },
                ]
            }).show();
        })
    }

    this.deleteObject = function (form, objectType, event, callBack) {
        event.preventDefault();
        new Promise(resolve => {
            let options = {
                isConfirmDialog: true,
                text:            $.sprintf(EfbHelper._('confirm.delete'), objectType),
                buttons:         [
                    {
                        text:  EfbHelper._('button.cancel'),
                        class: 'button text light cancel',
                        click() {
                            $(this).dialog("close");
                        }
                    },
                    {
                        text:  EfbHelper._('button.delete'),
                        class: 'button text delete',
                        click() {
                            $(this).dialog("close");
                            let comment = $.trim($(this).find('#change-log-comment').val());
                            return resolve(comment || null)
                        }
                    },
                ]
            };
            if (form.hasClass('change-log')) {
                options.dialogClass = 'light';
                options.html        = $('<div/>')
                    .append($('<p>' + $.sprintf(EfbHelper._('confirm.delete'), objectType) + '</p>'))
                    .append(new Abstract().getChangeLogCommentElement());
            }
            return new EfbHelperDialog(options).show();
        }).then(comment => {
            let data = {};
            if (comment) {
                data.change_log_comment = comment;
            }
            $.ajax({
                url:         $(event.target).attr('href'),
                type:        'get',
                dataType:    'json',
                data:        data,
                ajaxElement: $('body'),
                success(response) {
                    let formStatus = new FormStatus().init();
                    let status     = formStatus.getResponseStatus(response);
                    if (status === 'delete_ok') {
                        formStatus.addStatus(response, '', '', form.parent());
                        form.find('input,select,textarea').attr('readonly', true);
                        form.find('select,button,input[type=checkbox]').attr('disabled', true);
                        form.find('div.add-row').remove();
                        if (_.isFunction(callBack)) {
                            callBack.apply(this, [form, response, status])
                        } else {
                            let indexUrl = response.redirect || urlHelper.getUrl({
                                route:  {action: 'index'},
                                params: {}
                            });
                            setTimeout(() => {location.href = indexUrl}, 1000);
                        }
                    } else if (status === 'delete_conflict') {
                        EfbHelper.showStatusMessage(form, status);
                    }
                }
            });
        })
    };

    this.copyObject = function (form, callBack, event) {
        $.ajax({
            url:         $(event.target).attr('href'),
            type:        'get',
            dataType:    'json',
            ajaxElement: $('body'),
            success:     function (response) {
                if (_.isFunction(callBack)) {
                    callBack.apply(this, [form, response]);
                } else if (_.isFunction(callBack.init)) {
                    _private.instance.initCopyObject(form, response, callBack);
                }
            }
        });
    };

    this.initCopyObject = function (form, response, formObject) {
        if (_.isUndefined(response.data)) {
            return false;
        }
        let newForm = $(response.data);
        if (!newForm.is('form')) {
            newForm = newForm.find('form');
        }
        $(form).replaceWith(newForm);
        let state              = urlHelper.getState();
        state.params.object_id = 0;
        urlHelper.setState(state);
        formObject.init(newForm);
        return true;
    };

    this.getChangeLogCommentElement = () => {
        return $('<div class="formelement textarea full-width">' +
            '<label for="change-log-comment">COMMENT</label>' +
            '<textarea id="change-log-comment" rows="4" style="resize: none"></textarea>' +
            '</div>');
    }

});